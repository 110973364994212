.Content__foundations {
    padding: 100px 0px;
}
.Content__foundations--heading {
    text-align: center;
}
.Content__foundations--heading-title {
    margin-bottom: 15px;
}
.Content__foundations--heading-title h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}
.Content__foundations--heading-description {
    max-width: 1160px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.Content__foundations--heading-description p {
    font-size: 16px;
    font-weight: 300;
    color: var(--termPoliticsText);
    font-family: 'Poppins', sans-serif;
    line-height: 2.1;
}
.Content__foundations--heading-slogan {
    margin-bottom: 100px;
}
.Content__foundations--heading-slogan p {
    color: var(--sloganFoundations);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.Content__foundations--slider {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 40px;
}
.Content__foundations--slider-slide {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--textYellow);
    height: 250px;
    border-radius: 30px;
}

/* RESPONSIVE */

@media(max-width: 1100px) {
    .Content__foundations--slider {
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
    }
}
@media(max-width: 1024px) {
    .Content__foundations--slider {
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
}
@media(max-width: 600px) {
    .Content__foundations--slider {
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
}