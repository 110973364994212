.Content__bePartner {
    padding: 100px 0px;
}
.Content__bePartner--container {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 80px;
}
.Content__bePartner--icon {
    margin-bottom: 15px;
}
.Content__bePartner--icon img {
    max-width: 114px;
    width: 100%;
}
.Content__bePartner--title {
    margin-bottom: 20px;
}
.Content__bePartner--title h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}
.Content__bePartner--description {
    margin-bottom: 20px;
}
.Content__bePartner--description p {
    font-size: 16px;
    font-weight: 300;
    color: var(--termPoliticsText);
    font-family: 'Poppins', sans-serif;
    line-height: 2.1;
}
.Content__bePartner--slogan h4 {
    font-size: 33px;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}

/* FORM */

.Content__bePartner--form form {
    padding: 35px 50px;
    border: 1px solid var(--textYellow);
    border-radius: 25px;
}
.Content__bePartner--form-text {
    margin-bottom: 10px;
}
.Content__bePartner--form-text p {
    font-size: 16px;
    font-weight: 300;
    color: var(--termPoliticsText);
    font-family: 'Poppins', sans-serif;
    line-height: 1.4;
}
.Content__bePartner--form-inputField {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 15px;
}
.Content__bePartner--form-inputContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 30px;
}
.Content__bePartner--form-inputContainer label {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.Content__bePartner--form-inputContainer input {
    background-color: transparent;
    font-weight: 500;
    width: 100%;
    height: 40px;
    padding: 0px 20px;
    box-sizing: border-box;
    border: 1px solid var(--textYellow);
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Mulish', sans-serif;
    color: #fff;
    transition: .3s ease-in-out;
}
.Content__bePartner--form-inputContainer input:focus {
    border-color: #fff;
    transition: .3s ease-in-out;
}
.Content__bePartner--form-button button {
    width: 120px;
    height: 48px;
    font-family: 'Inter', sans-serif;
    background-color: var(--buttonYellow);
    color: #fff;
    border: 0;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.Content__bePartner--form-button button:hover {
    background-color: #fff;
    color: #000;
    transition: .3s ease-in-out;
}

/* RESPONSIVE */ 

@media(max-width: 1024px) {
    .Content__bePartner--container {
        gap: 30px !important;
        grid-template-columns: repeat(2,1fr);
    }
    .Content__bePartner--form form {
        padding: 30px;
    }
}
@media(max-width: 800px) {
    .Content__bePartner--container {
        grid-template-columns: repeat(1,1fr) !important;
    }
    .Content__bePartner--form form {
        padding: 30px 50px;
    }
}
@media(max-width: 600px) {
    .Content__bePartner--form-inputField {
        grid-template-columns: repeat(1,1fr) !important;
        gap: 0;
    }
}