@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('./Header.css');
@import url('./Login/Login.css');
@import url('./Home/Home.css');
@import url('./SingleRaffle/SingleRaffle.css');
@import url('./TermsAndConditions/TermsAndConditions.css');
@import url('./Foundations/Foundations.css');
@import url('./BePartner/BePartner.css');
@import url('./About/About.css');
@import url('./Resume.css');
@import url('./Footer.css');
:root {
    --bgPrimary: #171717;
    --themeSecundary: #E4ECF7;
    --topBarYellow: #FF9A00;
    --textYellow: #FFA500;
    --buttonYellow: #FFA300;
    --borderRaffleYellow: #FF7A00;
    --separatorGray: #9A9AB0;
    --footerBorder: #5F5F5F;
    --footerText: #737373;
    --textProductCardGray: #949494;
    --dateTextProductGray: #F2F2F2;
    --numberTextProduct: #505780;
    --newProductBG: #0067FF;
    --usedProductBG: #2CA900;
    --reconditionedProductBG: #5132A6;
    --specsOddBG: #D9D9D9;
    --specsPairBG: #CFCFCF;
    --availableNumbersColor: #A3A3A3;
    --tableAvailableBG: #505050;
    --tableAvailableBorder: #FF8D00;
    --availableNumberBG: #A7A7A7;
    --soldOutNumberBG: #FF3030;
    --resumeBlueText: #130847;
    --resumeCounterBlueBG: #001E37;
    --termPoliticsText: #ffffff7c;
    --sloganFoundations: #A2A2A2;
    --floatDropdownBorder: #C9C9C9;
}
* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
html {
    background-color: var(--bgPrimary);
}
a {
    text-decoration: none;
    color: inherit;
}
ul {
    list-style: none;
}
input, select, button, textarea {
    outline: none;
}
.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 10px;
    box-sizing: border-box;
}