.Content__testimonials {
    padding: 30px 0px;
    padding-bottom: 100px;
}
.Content__testimonials--container {
    position: relative;
}
.Content__testimonials--title {
    text-align: center;
    margin-bottom: 20px;
}
.Content__testimonials--title h2 {
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
}
.Content__testimonials--slide {
    padding: 0px 40px;
}
.Content__testimonials--slide-text {
    text-align: center;
    margin-bottom: 40px;
}
.Content__testimonials--slide-text p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.8;
}
.Content__testimonials--slide-name {
    text-align: center;
}
.Content__testimonials--slide-name h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    color: #fff;
}
.Content__testimonials--arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 33%;
}
.Content__testimonials--arrows > div {
    cursor: pointer;
}

/* RESPONSIVE */

@media(max-width: 480px) {
    .Content__testimonials--slide-text p {
        font-size: 16px;
    }
    .Content__testimonials--slide-name h4 {
        font-size: 18px;
    }
    .Content__testimonials--arrows {
        top: 41%;
    }
}