.Header__topbar {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--topBarYellow);
}
.Header__topbar--text p {
    font-size: 14px;
    font-weight: 400;
}
.Header__topbar--text p strong {
    font-weight: 700;
}
.Header__bottom {
    padding-top: 28px;
}
.Header__bottom--container {
    display: grid;
    grid-template-columns: 308px 1fr 310px;
    gap: 20px;
}
.Header__bottom--logo img {
    max-width: 308px;
    width: 100%;
    margin-top: -28px;
    margin-left: -32px;
}
.Header__bottom--navbar ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
}
.Header__bottom--navbar ul li {
    position: relative;
}
.Header__bottom--navbar ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
    font-size: 14px;
    transition: .3s ease-in-out;
}
.Header__bottom--navbar ul li a:hover {
    color: var(--textYellow);
    transition: .3s ease-in-out;
}
.dropdownContent {
    position: absolute;
    width: fit-content;
    white-space: nowrap;
    left: -34px;
    bottom: -82px;
    border: 1px solid var(--floatDropdownBorder);
    border-radius: 5px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: .3s ease-in-out;
}
.dropDownActive {
    transform: translateY(0) !important;
    opacity: 1 !important;
    visibility: initial !important;
    transition: .3s ease-in-out !important;
}
.dropdownContent ul {
    flex-direction: column;
    gap: 0;
}
.dropdownContent ul li {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--floatDropdownBorder);
}
.dropdownContent ul li:last-of-type {
    border-bottom: 0 !important;
}
.Header__bottom--actions {
    display: flex;
    align-items: center;
    gap: 35px;
}
.Header__bottom--actions-action {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
}
.sociosButton {
    padding: 5px 20px;
    font-family: 'Mulish', sans-serif;
    background-color: var(--tableAvailableBorder);
    border: 0;
    font-size: 16px;
    border-radius: 8px;
    color: #fff !important;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.sociosButton span {
    color: #fff !important;
}
.Header__bottom--actions-action a,
.Header__bottom--actions-action p {
    display: flex;
    align-items: center;
    gap: 8px;
}
.Header__bottom--actions-action span {
    color: var(--textYellow);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.Header__bottom--mobileBars {
    display: none;
    align-items: center;
    justify-content: center;
}
.Header__bottom--mobileBars img {
    max-width: 32px;
    width: 100%;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0000008a;
    z-index: 55;
    transform: translateX(100%);
    transition: .3s ease-in-out;
}
.overlayActive {
    transform: translateX(0);
    transition: .3s ease-in-out;
}

/* HEADER MOBILE */

.Content__mobileMenu--container {
    position: fixed;
    display: grid;
    grid-template-rows: 5px 1fr 100px;
    grid-template-areas: 
    "heading"
    "body"
    "footer";
    z-index: 555;
    top: 0;
    left: 0;
    width: 450px;
    background-color: var(--bgPrimary);
    border-right: 1px solid #ffffff2d;
    height: 100vh;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: .3s ease-in-out;
}
.menuActive {
    transform: translateX(0);
    transition: .3s ease-in-out;
}
.Content__mobileMenu--container::-webkit-scrollbar {
    display: none;
    visibility: hidden;
}
.Content__mobileMenu--nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 40px;
    background-color: var(--bgPrimary);
    grid-area: heading;
}
.Content__mobileMenu--nav ul {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    text-align: center;
    height: 50px;
    align-items: center;
    border-top: 1px solid #ffffff2d;
    border-bottom: 1px solid #ffffff2d;
}
.Content__mobileMenu--nav ul li {
    height: 100%;
}
.Content__mobileMenu--nav ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Content__mobileMenu--nav ul li span {
    margin-left: 5px;
    font-size: 14px;
    color: #fff;
}
.Content__mobileMenu--nav ul li i {
    font-size: 18px;
}
.linkActive {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}
.Content__mobileMenu--currentLink {
    padding: 10px 0px;
    text-align: center;
    border-bottom: 1px solid #ffffff2d;
    margin-top: 90px;
}
.Content__mobileMenu--currentLink h4 {
    font-size: 14px;
    font-weight: 600;
}
.Content__mobileMenu--mainNavs {
    grid-area: body;
}
.Content__mobileMenu--mainNavs-nav {
    display: none;
    margin-top: 90px;
}
.bodyActive2 {
    display: block;
}
.Content__mobileMenu--mainNavs-nav ul li {
    padding: 15px; 
    font-size: 15px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff2d;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.Content__mobileMenu--mainNavs-nav ul li:hover i {
    transform: rotate(180deg);
    transition: .4s ease-in-out;
} 
.Content__mobileMenu--mainNavs-nav ul li a {
    color: #ffffffcb;
    text-decoration: none;
}
.Content__menu--content-body {
    display: none;
}
.bodyActive {
    display: grid !important;
}
.Content__menu--content-body ul h4 {
    padding: 10px 15px;
    border-bottom: 1px solid #ffffff2d;
}
.Content__menu--content-body ul h4 a {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-decoration: none;
}
.Content__menu--content-body ul li {
    padding: 10px 15px;
    border-bottom: 1px solid #ffffff2d;
}
.Content__menu--content-body ul li a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
}

.Content__menu--content-body ul li {
    padding: 10px 15px;
    font-size: 14px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff2d;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.Content__mobileMenu--currentLink {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.Content__mobileMenu--currentLink i {
    position: absolute;
    left: 30px;
    padding-top: 2px;
    font-size: 14px;
    transform: rotate(180deg);
    cursor: pointer;
    display: none;
}

.mobileAccount {
    padding: 15px;
    grid-area: footer;
}
.mobileAccount span {
    display: block !important;
}
.mobileAccount p {
    text-align: center;
    justify-content: center;
    width: 50%;
    gap: 25px;
}
.mobileAccount a {
    justify-content: center;
    text-align: center;
    width: 50%;
}

/* RESPONSIVE */

@media(max-width: 1250px) {
    .Header__bottom--navbar ul li a {
        font-size: 13px;
    }
}
@media(max-width: 1200px) {
    .Header__bottom--navbar {
        display: none;
    }
    .Header__bottom--container {
        grid-template-columns: 32px 1fr 310px;
        grid-template-areas: 
        "bars logo actions";
    }
    .Header__bottom--logo {
        grid-area: logo;
        text-align: center;
    }
    .Header__bottom--logo img {
        max-width: 280px;
    }
    .Header__bottom--actions {
        grid-area: actions;
    }
    .Header__bottom--mobileBars {
        grid-area: bars;
    }
    .Header__bottom--actions {
        justify-content: center;
    }
    .Header__bottom--mobileBars {
        display: flex;
    }
}
@media(max-width: 768px) {
    .Header__bottom--logo img {
        max-width: 200px;
    }
    .Header__bottom--actions {
        gap: 20px;
    }
}
@media(max-width: 600px) {
    .Header__topbar--text p {
        font-size: 12px;
    }
    .Header__bottom--container {
        grid-template-columns: 32px 1fr 54px;
    }
    .Header__bottom--actions-action span {
        display: none;
    }
    .Header__bottom--logo img {
        max-width: 200px;
        margin-left: -25px;
    }
    .accountAction {
        display: none;
    }
}
@media (max-width:500px) {
    .Content__mobileMenu--container {
        width: 80%;
    }
}