.Content__resume {
    position: fixed;
    height: 100vh;
    max-width: 530px;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    right: -100%;
    z-index: 2;
    background-color: #000;
    padding: 40px 90px;
    z-index: 555;
    transition: .3s ease-in-out;
}
.resumeActive {
    right: 0;
    transition: .3s ease-in-out;
}
.Content__resume {
    overflow-y: auto;
}
.Content__resume::-webkit-scrollbar {
    display: none;
}
.Content__resume--title {
    margin-bottom: 3px;
}
.Content__resume--title h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}
.Content__resume--choosenNumbers-title {
    margin-bottom: 30px;
}
.Content__resume--choosenNumbers-title p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.Content__resume--choosenNumbers-table {
    display: grid;
    grid-template-columns: repeat(5, 54px);
    width: fit-content;
    gap: 10px;
    max-height: 375px;
    overflow: auto;
    padding-right: 20px;
    margin-bottom: 40px;
}
.Content__resume--choosenNumbers-table::-webkit-scrollbar {
    width: 6px;
}
.Content__resume--choosenNumbers-table::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
}
.Content__resume--choosenNumbers-table::-webkit-scrollbar-track {
    background-color: transparent;
}
.resume__choosenNumbers--table-number {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--availableNumberBG);
}
.resume__choosenNumbers--table-number p {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    font-family: 'Nunito', sans-serif;
}
.Content__resume--message {
    margin-bottom: 20px;
}
.Content__resume--message p {
    font-size: 12px;
    font-family: 'Nunito', sans-serif;
    color: var(--tableAvailableBorder);
}
.Content__resume--totalNumbers-title {
    margin-bottom: 10px;
}
.Content__resume--totalNumbers-title h4 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.Content__resume--totalNumbers-table {
    padding: 20px;
    padding-top: 30px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 40px;
}
.resume__totalNumbers--table-heading {
    margin-bottom: 20px;
}
.resume__totalNumbers--table-heading p {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--resumeBlueText);
    font-size: 15px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}
.resume__totalNumbers--table-heading p strong {
    padding: 8px 12px;
    background-color: var(--resumeCounterBlueBG);
    color: #fff;
    border-radius: 5px;
}
.resume__totalNumbers--table-button button {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: var(--textYellow);
    padding: 10px 25px;
    border: 1px solid var(--textYellow);
    width: fit-content;
    border-radius: 8px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.resume__totalNumbers--table-button button:hover {
    background-color: transparent;
    color: #000;
    transition: .3s ease-in-out;
}
.Content__resume--otherRaffles-title {
    margin-bottom: 10px;
}
.Content__resume--otherRaffles-title h4 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.Content__resume--otherRaffles-raffle img {
    max-width: 100%;
}

/* RESPONSIVE */

@media(max-width: 768px) {
    .Content__resume {
        padding: 40px 50px;
    }
}
@media(max-width: 600px) {
    .Content__resume {
        width: 90%;
    }
}
@media(max-width: 480px) {
    .Content__resume {
        padding: 30px;
    }
}