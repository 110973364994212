.Content__availableNumbers--title {
    margin-bottom: 30px;
}
.Content__availableNumbers--title h2 {
    font-size: 32px;
    font-weight: 700;
    color: var(--buttonYellow);
    font-style: italic;
    font-family: 'Poppins', sans-serif;
}
.Content__availableNumbers--table {
    display: grid;
    grid-template-columns: repeat(10, 54px);
    width: fit-content;
    gap: 10px;
    background-color: var(--tableAvailableBG);
    border: 1px solid var(--tableAvailableBorder);
    padding: 30px;
    border-radius: 12px;
    margin-bottom: 25px;
}
.Content__availableNumbers--table-number {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--availableNumberBG);
}
.Content__availableNumbers--table-number p {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    font-family: 'Nunito', sans-serif;
}
.chosenNumber {
    background-color: var(--textYellow) !important;
}
.soldOutNumber {
    background-color: var(--soldOutNumberBG) !important;
}
.Content__availableNumbers--meanings {
    display: flex;
    align-items: center;
    gap: 30px;
}
.Content__availableNumbers--meanings-meaning {
    display: flex;
    align-items: center;
    gap: 10px;
}
.availableNumbers__meanings--meaning-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--tableAvailableBG);
}
.availableNumbers__meanings--meaning-text span {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}